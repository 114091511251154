.header-img {
    max-width: 80% !important;
}

/* You can add global styles to this file, and also import other style files */
mat-card-content {
    border-radius: 10px !important;
}

mat-card {
    border-radius: 10px !important;
}

/* .sidebar {
    background-color: #E4F1F5 !important;
} */

/* snackbar */

.ERROR {
    background-color: #FF0000 !important;
    color: white !important;
}

.WARN {
    background-color: #eed202 !important;
    color: white !important;
}

.INFO {
    background-color: #5cb85c !important;
    color: white !important;
}

.mat-simple-snackbar-action {
    color: white !important;
}

/* buttons */

.greenButton {
    background-color: green;
    color: white;
}

.greenButton[disabled]:hover {
    background-color: green;
}

/* dashboard */

.white-button {
    background: #FFFFFF;
    border-radius: 20px !important;
    color: #1C3775;
}

/* paginator */

.mat-paginator {
    border: 1px solid #1C3775;
    border-radius: 10px;
}

/* table responsive */

.example-container {
    display: flex;
    flex-direction: column;
    max-height: 550px;
    min-width: 200px;
}

.mat-table {
    overflow: auto;
    max-height: 550px;
}

.mat-header-row {
    background-color: #1C3775;
}

.mat-header-cell {
    color: white;
}

.mobile-label {
    display: none;
}

@media(max-width: 1180px) {
    mat-cell:first-of-type {
        padding-left: 0 !important;
    }

    .mobile-label {
        width: 80px;
        display: inline-block;
        font-weight: bold;
    }

    .mobileHeader {
        display: none;
    }

    .mat-row {
        flex-direction: column;
        align-items: start;
        padding: 8px 24px;
    }
}

.hover-row:hover {
    background-color: #EDF4F8;
}

/* paginator on mobile */

@media(max-width: 470px) {
    .mat-paginator-range-label {
        margin: 0 10px 0 10px !important;
    }
}